import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logoDark from '../../../assets/images/DM-white without backg-01 4.png';
import image1 from '../../../assets/images/facebook.png';
import image4 from '../../../assets/images/linkedin.png';
import image2 from '../../../assets/images/DM-white without backg-01 4.png';
import image3 from '../../../assets/images/Bouton.png';

import './Footer.css';
import '../../../assets/styles/styles.css';

const Footer = () => {


  const [theme, setTheme] = useState(localStorage.getItem('theme'));

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  const [activeLink, setActiveLink] = useState('');
  const location = useLocation();

  const handleClick = (link) => {
    setActiveLink('');
    document.querySelector('body').classList.add('scroll-to-top');
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      document.querySelector('body').classList.remove('scroll-to-top');
    }, 100);
  };

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  return (
    <footer className="footer">
      <div className="foo_total">
        <div className="footer__logo">
          <Link to="/">
            <img src={logoDark} alt="Logo" className="logo" onClick={() => handleClick('/')} />
          </Link>
          <p>Des solutions avancées en réalité augmentée (AR), jeux 2D et 3D,
            gamification marketing et intelligence artificielle pour différencier votre business et réussir.</p>
        </div>

        <div >
          <ul className="footer__links">
            <li>
              <Link
                to="/"
                className={activeLink === '/' ? 'active' : ''}
                onClick={() => handleClick('/')}
              >
                Accueil
              </Link>
            </li>
            <li className="dropdown">
              <Link
                to="/services"
                className={activeLink === '/services' ? 'active' : ''}
                onClick={() => handleClick('/services')}
              >
                Services
              </Link>
              <div className="dropdown-content">
                <Link to={{ pathname: '../Services', hash: '#developpement-ar-vr' }}>Développement AR/VR</Link>
                <Link to={{ pathname: '../Services', hash: '#jeux-2D-et-3D' }}>Développement de jeux 2D et 3D</Link>
                <Link to={{ pathname: '../Services', hash: '#solutions-IA' }}>Développement de solutions d'IA</Link>
                <Link to={{ pathname: '../Services', hash: '#gamification-marketing' }} >Développement de solutions de gamifaction marketing</Link>
                
              </div>
            </li>

            <li>
              <Link
                to="/contact"
                className={activeLink === '/contact' ? 'active' : ''}
                onClick={() => handleClick('/contact')}
              >
                Contact
              </Link>
            </li>
            <li>
              <Link
                to="/apropos"
                className={activeLink === '/apropos' ? 'active' : ''}
                onClick={() => handleClick('/apropos')}
              >
                A propos
              </Link>
            </li>
            <li>
              <Link
                to="/Rejoignez-nous"
                className={activeLink === '/Rejoignez-nous' ? 'active' : ''}
                onClick={() => handleClick('/Rejoignez-nous')}
              >
                Rejoignez-nous
              </Link>
            </li>
          </ul>
        </div>
        <div >
          <div className="footer__images">
            <div style={{ display: "flex", gap: "7px" }}>
              <a href='https://www.facebook.com/dmnova.technologie'>
                <img src={image1} alt="Image 1" className="image1" />
              </a>
              <a href='https://www.linkedin.com/company/dmnova-tech'>
                <img src={image4} alt="Image 1" className="image1" />
              </a>
            </div>
            <Link to="/" onClick={() => handleClick('/')}>
              <img src={image2} alt="Image 2" className="image2" />
            </Link>
          </div>
        </div>
        <div>
          <div className="footer__button">
            <button className="footer__button" onClick={() => handleClick('/')}>
              <img src={image3} alt="Button Image" className="footer__button-image" />
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

// Contact_map.js
import React from "react";
import "./Contact_map.css";
import { Helmet } from "react-helmet";
const Contact_map = () => {
  const mapStyles = {
    border: 0,
  };

  return (
    <div className="container_map">
      <Helmet>
        <title>Contactez DMNova - Solutions en AR, VR, Jeux 2D/3D et IA</title>

        <meta
          name="description"
          content="Besoin de solutions en réalité augmentée (AR), réalité virtuelle (VR), jeux 2D/3D, gamification marketing ou IA ? Contactez DMNova pour des réponses à vos questions."
        />
      </Helmet>
      <div className="Map__block gradient-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12940.731273463394!2d10.5921116!3d35.81999!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd8aebdb6f220d%3A0x83109a00ef5984cb!2sSoftTech%20la%20p%C3%A9pini%C3%A8re!5e0!3m2!1sfr!2stn!4v1691486079487!5m2!1sfr!2stn"
          style={mapStyles}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact_map;

import React, { useState, useEffect } from 'react';
import Navbar from '../../layout/Navbar/Navbarr'; 
import Apropos_Introduction from '../../common/Apropos_Introduction/Apropos_Introduction'; 
import Apropos_Definition from '../../common/Apropos_Definition/Apropos_Definition'; 
import Apropos_Definition_SmallScreen from '../../common/Apropos_Definition_SmallScreen/Apropos_Definition_SmallScreen';
import Apropos_Information_SmallScreen from '../../common/Apropos_Information_SmallScreen/Apropos_Information_SmallScreen'; 
import Apropos_Information from '../../common/Apropos_Information/Apropos_Information'; 
import Footer from '../../layout/Footer/Footer'; 

function Apropos() {
  const [isBigScreen, setIsBigScreen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsBigScreen(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [isBig, setIsBig] = useState(window.innerWidth > 600);

  useEffect(() => {
    const handleResize = () => {
      setIsBig(window.innerWidth > 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
      <div className="navbar_background">
        <Navbar />
      </div>
      <Apropos_Introduction />
      {isBigScreen ? (
        <Apropos_Definition />
      ) : (
        <Apropos_Definition_SmallScreen />
      )}
      {isBig ? (
       <Apropos_Information/>
      ) : (
        <Apropos_Information_SmallScreen />
      )}
      <Footer />
    </>
  );
}

export default Apropos;

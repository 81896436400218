import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Accueil_Introduction.css';

function Accueil_Introduction() {
  const navigate = useNavigate();
  const handleScrollDown = () => {
    window.scrollTo({ top: window.innerHeight, behavior: 'smooth' });
  };

  return (
    <div className="Accueil_container">
      <h1>Osez l'innovation et démarquez-vous de la concurrence !</h1>
      <p>Des solutions avancées en réalité augmentée (AR), réalité virtuelle (VR) et jeux 2D/3D pour vous différencier et réussir votre business.</p>
      <button className="Accueil_btn" onClick={() => navigate('../services')}>
        Explorer nos services
      </button>
      <div className="ScrollDown_button" onClick={handleScrollDown}>
        Scroll Down
        <div className="Arrow"></div>
      </div>
    </div>
  );
}

export default Accueil_Introduction;

import React from 'react';
import { useEffect } from 'react';
import useLocalStorage from 'use-local-storage';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import AR from '../../../assets/images/AR.png';
import ARlight from '../../../assets/images/img2.png';
import IA from '../../../assets/images/img1.png';
import marketing from '../../../assets/images/img3.png';
import jeux from '../../../assets/images/game.png';
import Fade from 'react-reveal/Fade';
import './Service_About_SmallScreen.css';
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";

function SmallScreen() {
  const navigate = useNavigate();
  const [theme, setTheme] = useState(localStorage.getItem('theme'));

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (

    <div className="Service_SmallScreen">
        <Helmet>
        <title>
          Services - Développement AR, Jeux 2D/3D, Gamification et IA | DM Nova
        </title>

        <meta
          name="description"
          content="Explorez nos services spécialisés : développement de solutions AR, jeux immersifs 2D/3D, gamification marketing et intégration d'intelligence artificielle. Contactez-nous pour concrétiser vos projets."
        />
      </Helmet>
      <Fade left duration={3000}>
        <div className="Contenu" id="developpement-ar-vr-responsive">
          <h1 className="titre_smallscreen">Développement AR/VR</h1>
          <img className="left-side-Service" src={theme === 'dark' ? AR : ARlight} alt="Image" />
          <p className="service_p_SmallScreen">
            Découvrez nos services AR/VR innovants ! Nous concevons des solutions en réalité augmentée sur mesure,
            incluant les projections 3D, les rull-up 3D, les packagings AR, les publicités AR et les essais virtuels.
            Plongez vos utilisateurs dans des mondes virtuels captivants grâce à notre expertise en Réalité Virtuelle.
            Des simulations réalistes aux jeux VR stimulants, nos expériences immersives sont inoubliables.
          </p>
          <button className="responsive_bouton" onClick={() => navigate('../Client_Douté')}>Commencez un projet</button>
        </div>
      </Fade>
      <Fade right duration={3000}>
        <div className="Contenu" id="jeux-2D-et-3D-responsive">
          <h1 className="titre_smallscreen">Développement de jeux 2D et 3D</h1>
          <img className="left-side-Service" src={jeux} alt="Image" />
          <p className="service_p_SmallScreen">
            Nous avons une équipe de développeurs de jeux hautement qualifiés qui peuvent créer des jeux immersifs et interactifs
            en 2D et 3D sur les plateformes Unity et Unreal. Nous travaillons en étroite collaboration avec nos clients pour créer des jeux
            personnalisés qui correspondent à leurs marques et qui répondent à leurs besoins.
          </p>
          <button className="responsive_bouton" onClick={() => navigate('../Client_Douté')}>Commencez un projet</button>
        </div>
      </Fade>
      <Fade left duration={3000}>
        <div className="Contenu" id="solutions-IA-responsive">
          <h1 className="titre_smallscreen">Développement de solutions d'IA</h1>
          <img className="left-side-Service" src={IA} alt="Image" />
          <p className="service_p_SmallScreen">
            Nous intégrons des fonctions d'intelligence artificielle dans nos solutions pour créer des
            expériences utilisateur plus immersives. Nos solutions d'IA incluent la reconnaissance vocale,
            la synthèse vocale et les systèmes de recommandation.
            Nous travaillons avec vous pour créer des solutions sur mesure qui répondent à vos besoins spécifiques.
          </p>
          <button className="responsive_bouton" onClick={() => navigate('../Client_Douté')}>Commencez un projet</button>
        </div>
      </Fade>
      <Fade right duration={3000}>
        <div className="Contenu" id="gamification-marketing-responsive">
          <h1 className="titre_smallscreen">Développement de solutions de gamification marketing</h1>
          <img className="left-side-Service" src={marketing} alt="Image" />
          <p className="service_p_SmallScreen">
            Nous croyons que la gamification est un outil puissant pour engager votre public et
            créer des expériences mémorables. Nous pouvons vous aider à créer des campagnes de marketing
            interactif en utilisant des solutions de gamification pour susciter l'intérêt de votre public et renforcer
            l'image de votre marque.
          </p>
          <button className="responsive_bouton" onClick={() => navigate('../Client_Douté')}>Commencez un projet</button>
        </div>
      </Fade>
    </div>

  );
}

export default SmallScreen;

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Accueil_partenaire.css';
import partenaire from '../../../assets/images/partenaire1.png';
import partenaire2 from '../../../assets/images/partenaire2.png';
import partenaire3 from '../../../assets/images/partenaire3.png';
import partenaire4 from '../../../assets/images/partenaire4.png';
import partenaire5 from '../../../assets/images/partenaire5.png';
import partenaire6 from '../../../assets/images/partenaire6.png';

function Accueil_Partenaire() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024, // For screens with a width of 1024px and above
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600, // For screens with a width of 600px and below
        settings: {
          slidesToShow: 3, // Show 3 images at a time
          slidesToScroll: 1, // Scroll one image at a time
        },
      },
    ],
  };

  return (
    <div className="Partenaire">
      <h2 className="Partenaire_titre">
        Ils nous <span> font confiance</span>
      </h2>
      <div className="Partenaire__container">
        <Slider {...settings}>
          <div>
            <img src={partenaire6} alt="Image" className="partenaire_image" />
          </div>
          <div>
            <img src={partenaire3} alt="Image" className="partenaire_image3" />
          </div>
          <div>
            <img src={partenaire5} alt="Image" className="partenaire_image" />
          </div>
          <div>
            <img src={partenaire} alt="Image" className="partenaire_image1" />
          </div>
          <div>
            <img src={partenaire4} alt="Image" className="partenaire_image4" />
          </div>
          <div>
            <img src={partenaire2} alt="Image" className="partenaire_image2" />
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default Accueil_Partenaire;

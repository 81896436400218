import React, { useState, useEffect } from 'react';
import Navbar from '../../layout/Navbar/Navbarr';
import Contact_Introduction from '../../common/Contact_Introduction/Contact_Introduction';
import Formulaire from '../../common/Formulaire/Formulaire';
import Contact_map from '../../common/Contact_map/Contact_map';
import Apropos_Information from '../../common/Apropos_Information/Apropos_Information';
import Apropos_Information_SmallScreen from '../../common/Apropos_Information_SmallScreen/Apropos_Information_SmallScreen'; 
import Footer from '../../layout/Footer/Footer';

function Contact() {
  const [isBigScreen, setIsBigScreen] = useState(window.innerWidth < 600);
  const [isBig, setIsBig] = useState(window.innerWidth === 768);

  useEffect(() => {
    const handleResize = () => {
      setIsBigScreen(window.innerWidth < 600);
      setIsBig(window.innerWidth === 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <div className="navbar_background">
        <Navbar />
      </div>
      <Contact_Introduction />
      <Formulaire />

      {isBigScreen ? (
        <Apropos_Information_SmallScreen />
      ) : null}
      
      {isBig ? (
        <Apropos_Information />
      ) : null}
      
      <Contact_map />
      <Footer />
    </div>
  );
}

export default Contact;

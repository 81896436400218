import React from 'react';
import {useEffect } from 'react';
import useLocalStorage from 'use-local-storage';
import { useNavigate } from 'react-router-dom';
import Arrow from '../../../assets/images/Arrow 10.png';
import Fade from 'react-reveal/Fade';
import "../../../assets/styles/styles.css";
import './Apropos_Information.css';
import { useState } from 'react';

function Information() {
  const navigate = useNavigate();
  const [theme, setTheme] =useState(localStorage.getItem('theme'));

  useEffect(() => {
      document.documentElement.setAttribute('data-theme', theme);
      localStorage.setItem('theme', theme);
    }, [theme]);
  
  
  return (
    <div className="Information_Apropos">
      <div className= "Information__container_Apropos">
      <Fade left duration={2000}>
        <div className="Information__cadre1_Apropos">
          <h2 className="Information__cadre1_titre_Apropos">Nos informations</h2>
          <p className="Information__cadre1_Information_Apropos">
            <span>contact@dmnova.com</span>
            <span>+216 55 544 822</span>
            <span>Pépinière Soft-tech du technopôle de Sousse, NOVATION CITY</span>
          </p>
        </div>
        </Fade>
        <Fade right duration={2000}>
        <div className="Information__cadre2_Apropos">
          <h2 className="Information__cadre_titre2_Apropos">Contactez nous pour plus du détail</h2>
          <img src={Arrow} alt="Image" className="Information__cadre_image" onClick={() => navigate('../Client_Douté')} />
        </div>
        </Fade>
      </div>
      
    </div>
  );
}

export default Information;

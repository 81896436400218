import React, { useState, useEffect } from 'react';
import Navbar from '../../layout/Navbar/Navbarr'; 
import Rejoigner_Introduction from '../../common/Rejoigner_Introduction/Rejoigner_Introduction'; 
import Rejoigner_formulaire from '../../common/Rejoigner_formulaire/Rejoigner_formulaire';
import Apropos_Information from '../../common/Apropos_Information/Apropos_Information';
import Apropos_Information_SmallScreen from '../../common/Apropos_Information_SmallScreen/Apropos_Information_SmallScreen'; 
import Footer from '../../layout/Footer/Footer'; 

function Rejoigner() {
  const [isBig, setIsBig] = useState(window.innerWidth > 600);

  useEffect(() => {
    const handleResize = () => {
      setIsBig(window.innerWidth > 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <div className="navbar_background">
        <Navbar />
      </div>
      <Rejoigner_Introduction />
      <Rejoigner_formulaire /> 
      {isBig ? (
       <Apropos_Information/>
      ) : (
        <Apropos_Information_SmallScreen />
      )}
      <Footer />
     
    </div>
  )
}

export default Rejoigner

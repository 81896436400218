import React from "react";
import "./Accueil_Service_SmallScreen.css";
import { useNavigate } from "react-router-dom";
import Group from "../../../assets/images/Group.png";
import Group2 from "../../../assets/images/Group 171.png";
import Group3 from "../../../assets/images/Group 172.png";
import Group4 from "../../../assets/images/Group 173.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
function AccueilServiceSmallScreen() {
  const navigate = useNavigate();

  return (
    <div className="service_small">
      <Helmet>
        <title>
          DM Nova - Solutions en AR, VR, Jeux 2D/3D et IA au Service du
          Marketing
        </title>

        <meta
          name="description"
          content="Découvrez DM Nova, votre partenaire pour des solutions innovantes en réalité augmentée (AR), réalité virtuelle (VR) et jeux 2D/3D. Transformez votre marketing avec nos services d'intelligence artificielle et de gamification."
        ></meta>
      </Helmet>
      <div className="Accueil_service_SmallScreen">
        <p className="title_smallScreen">Nos Services</p>
        <div className="bloc_with_dots">
          <div className="CarouselContainer_AccueilService">
            <div className="CarouselItem_AccueilService">
              <img
                src={Group}
                alt="Image"
                className="ServiceSmallScreen__cadre_image"
              />
              <h2 className="CarouselTitle_AccueilService">
                Développement de solutions de gamification marketing
              </h2>
              <p className="CarouselContent_AccueilService">
                Nous croyons que la gamification est un outil puissant pour
                engager votre public et créer des expériences mémorables.
              </p>
              <div className="readMore-responsive">
                <Link
                  to={{
                    pathname: "../Services",
                    hash: "#gamification-marketing-responsive",
                  }}
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="CarouselItem_AccueilService">
              <img
                src={Group2}
                alt="Image"
                className="ServiceSmallScreen__cadre_image"
              />
              <h2 className="CarouselTitle2_AccueilService">
                Développement AR/VR
              </h2>
              <p className="CarouselContent_AccueilService">
                Découvrez nos services AR/VR de pointe ! Nos expériences
                immersives sont mémorables.
              </p>
              <div className="readMore-responsive">
                <Link
                  to={{
                    pathname: "../Services",
                    hash: "#developpement-ar-vr-responsive",
                  }}
                >
                  Read More
                </Link>
              </div>
            </div>

            <div className="CarouselItem_AccueilService">
              <img
                src={Group3}
                alt="Image"
                className="ServiceSmallScreen__cadre_image3"
              />
              <h2 className="CarouselTitle2_AccueilService">
                Développement de solutions d'IA
              </h2>
              <p className="CarouselContent3_AccueilService">
                Nous intégrons des fonctions d'intelligence artificielle dans
                nos solutions pour créer des expériences utilisateur plus
                immersives.
              </p>
              <div className="readMore-responsive">
                <Link
                  to={{
                    pathname: "../Services",
                    hash: "#solutions-IA-responsive",
                  }}
                >
                  Read More
                </Link>
              </div>
            </div>

            <div className="CarouselItem_AccueilService">
              <img
                src={Group4}
                alt="Image"
                className="ServiceSmallScreen__cadre_image4"
              />
              <h2 className="CarouselTitle_AccueilService">
                Développement de jeux 2D et 3D
              </h2>
              <p className="CarouselContent_AccueilService">
                Nous avons une équipe de développeurs de jeux hautement
                qualifiés qui peuvent créer des jeux immersifs et interactifs en
                2D et 3D sur les plateformes Unity et Unreal.
              </p>
              <div className="readMore-responsive">
                <Link
                  to={{
                    pathname: "../Services",
                    hash: "#jeux-2D-et-3D-responsive",
                  }}
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>

          <div class="loader">
            <span class="loader__dot">.</span>
            <span class="loader__dot">.</span>
            <span class="loader__dot">.</span>
          </div>
        </div>

        <div className="responsive_btn">
          <button
            className="btn_projet_smallscreen"
            onClick={() => navigate("../Client_Douté")}
          >
            Commencez un projet
          </button>
        </div>
      </div>
    </div>
  );
}

export default AccueilServiceSmallScreen;

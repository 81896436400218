import React from "react";
import { useEffect } from "react";
import useLocalStorage from "use-local-storage";
import globe from "../../../assets/images/DM Nova web site (6).gif";
import scroll from "../../../assets/images/Scroll down.png";
import scrollImageLight from "../../../assets/images/Group 181.png";
import Fade from "react-reveal/Fade";
import "../../../assets/styles/styles.css";
import "./Apropos_Introduction.css";
import { Helmet } from "react-helmet";
function Apropos_Introduction() {
  const [theme, setTheme] = useLocalStorage("theme", "dark");
  const handleScroll = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <>
      <div className="About">
        <Helmet>
          <title>
            À Propos de DM Nova - Expertise en AR, Jeux 2D/3D, Gamification et
            IA
          </title>

          <meta
            name="description"
            content="Découvrez DM Nova, une entreprise de technologies innovantes spécialisée en réalité augmentée (AR), jeux immersifs 2D/3D, gamification marketing et solutions d'intelligence artificielle (IA)."
          />
        </Helmet>
        <div className="About__container3">
          <div className="style">
            <div className="About__block3">
              <Fade top duration={3000}>
                <h1 className="titre3">
                  La technologie est la clé pour transformer les entreprises.
                </h1>
              </Fade>
              <Fade top duration={3000}>
                <p className="paragraphe3">
                  Nous sommes une entreprise de technologies innovantes
                  spécialisée dans la réalité augmentée (AR), les jeux 2D et 3D,
                  la gamification marketing et les solutions d'intelligence
                  artificielle (AI).
                </p>
              </Fade>
            </div>
          </div>
          <div className="About__block3">
            <img src={globe} alt="Ma image" className="img" />
          </div>
        </div>

        <div className="scroll__container">
          <div className="scroll__button">
            <button className="scroll__button" onClick={handleScroll}>
              <img
                src={theme === "dark" ? scroll : scrollImageLight}
                alt="btn img"
                className="scroll__button-image"
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Apropos_Introduction;

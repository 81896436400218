import React, { useState, useEffect } from 'react';
import Navbar from '../../layout/Navbar/Navbarr'; 
import Introduction from '../../common/Service_Introduction/Introduction';
import About from '../../common/Service_About/About';
import Service_About_SmallScreen from '../../common/Service_About_SmallScreen/Service_About_SmallScreen';
import Footer from '../../layout/Footer/Footer'; 

function Service() {
  const [isBigScreen, setIsBigScreen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsBigScreen(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="navbar_background">
        <Navbar />
      </div>
        <Introduction />
        {isBigScreen ? (
         <About />
         ) : (
        <Service_About_SmallScreen />
      )}
        <Footer></Footer>
      </> 
  );
}

export default Service;

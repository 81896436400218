import React from "react";
import aproposImage from "../../../assets/images/apropos.png";
import Fade from "react-reveal/Fade";
import "./Apropos_Definition.css";
import "../../../assets/styles/styles.css";
import { Helmet } from "react-helmet";
function Apropos_Definition() {
  return (
    <div className="Apropos_Definition">
      <Helmet>
        <title>
          À Propos de DM Nova - Expertise en AR, Jeux 2D/3D, Gamification et IA
        </title>

        <meta
          name="description"
          content="Découvrez DM Nova, une entreprise de technologies innovantes spécialisée en réalité augmentée (AR), jeux immersifs 2D/3D, gamification marketing et solutions d'intelligence artificielle (IA)."
        />
      </Helmet>
      <div className="Apropos_Definition__container">
        <Fade left duration={3000}>
          <div className="Apropos_Definition__block">
            <img src={aproposImage} alt="Ma image" className="Apropos_Image" />
          </div>
        </Fade>
        <div className="Apropos_Definition__block">
          <p className="Apropos_Definition_paragraphe">
            <Fade top duration={3000}>
              <span>
                Notre entreprise a été fondée avec une vision claire : créer des
                solutions technologiques uniques pour aider les entreprises à
                atteindre leurs objectifs commerciaux. Nous sommes passionnés
                par l'innovation et nous nous engageons à fournir à nos clients
                des solutions personnalisées pour répondre à leurs besoins
                uniques.
              </span>
            </Fade>
            <Fade top duration={3000}>
              <span>
                Chez DM Nova, nous croyons que la technologie est un outil
                puissant pour changer le monde, et nous sommes fiers d'être à la
                pointe de l'innovation. Nous sommes engagés à aider nos clients
                à réussir en offrant des solutions de pointe qui ont un impact
                réel sur leurs entreprises.
              </span>
            </Fade>
            <Fade top duration={3000}>
              <span>
                Nous sommes heureux de travailler avec des clients de différents
                secteurs, notamment des agences de communication et des
                entreprises B2B. Si vous êtes intéressés à travailler avec nous,
                n'hésitez pas à nous contacter.
              </span>{" "}
            </Fade>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Apropos_Definition;

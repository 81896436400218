import React from 'react';
import './Apropos_Information_SmallScreen.css';
import { useNavigate } from 'react-router-dom';
import Arrow from '../../../assets/images/Arrow 10.png';
import { Helmet } from 'react-helmet';
function AproposInformationSmallScreen() {
    const navigate = useNavigate();

  return (
    <div className="CarouselContainer_SmallScreen">
       <Helmet>
          <title>
            À Propos de DM Nova - Expertise en AR, Jeux 2D/3D, Gamification et
            IA
          </title>

          <meta
            name="description"
            content="Découvrez DM Nova, une entreprise de technologies innovantes spécialisée en réalité augmentée (AR), jeux immersifs 2D/3D, gamification marketing et solutions d'intelligence artificielle (IA)."
          />
        </Helmet>
      <div className="CarouselItem_SmallScreen">
        <h2 className="CarouselTitle_SmallScreen">Nos informations</h2>
        <p className="CarouselContent_SmallScreen">
          <span>contact@dmnova.com</span>
          <span>+216 55 544 822</span>
          <span>Pépinière Soft-tech du technopôle de Sousse, NOVATION CITY</span>
        </p>
      </div>
      <div class="Apropos_loader"><span class="Apropos_loader__dot">.</span><span class="Apropos_loader__dot">.</span><span class="Apropos_loader__dot">.</span></div>

      <div className="CarouselItem2_SmallScreen">
        <h2 className="CarouselTitle2_SmallScreen">Contactez nous pour plus de détails</h2>
        <img src={Arrow}
          alt="Image"
          className="CarouselImage2_SmallScreen"
          onClick={() => navigate('../Client_Douté')}
        />
      </div>
    </div>
  );
}

export default AproposInformationSmallScreen;

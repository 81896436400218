import React, { useRef } from 'react';
import {useEffect } from 'react';
import useLocalStorage from 'use-local-storage';
import '../../layout/Navbar/Navbar.css';
import Fade from 'react-reveal/Fade';
import "../../../assets/styles/styles.css";
import './Formulaire.css';
import { useState } from 'react';
import emailjs from 'emailjs-com';


function Formulaire() {
  const [theme, setTheme] =useState(localStorage.getItem('theme'));

useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

    const sendEmail = (e) => {
      e.preventDefault();
     
      emailjs.sendForm('service_3eysbae', 'template_wxwcyyr', document.getElementById("formContact"), '6Ece5qpnem9xRQtMk')
        .then((result) => {
          alert('Email sent successfully', result);
        }, (error) => {
          alert('Email sending failed:', error);
        });
    };

    const R1=useRef()
  return (
    <div className="Formulaire">
        <Fade top duration={3000}>
        <h1 className="Formulaire_titre">Nous Contacter</h1>
        </Fade>
      <div className="Formulaire__container">
        <div className="form">
          <div className="Formulaire__block">
          <Fade left duration={3000}>
              <form id='formContact'>
                <div className="Formulaire__inputs-container">
                  <input name="from_name" id="from_name" type="text"  placeholder="Nom" required />
                  <input type="email" name="from_email" id="from_email" placeholder="E-mail" required />
                </div>
                <div className="Formulaire__inputs-container">
                  <input type="tel" name="from_phone" id="from_phone" placeholder="Numéro de téléphone" required />
                  <input type="text" name="from_subject" id="from_subject" placeholder="Objet" required />
                </div>
                <div className="Formulaire__inputs-container">
                  <textarea name="message" id="message" placeholder="Écrire votre message ici" required />
                </div>
                <div className="Formulaire__container_btn">
                  <div>
                    <button type="button" className="Formulaire__reset-button-forum">Réinitialiser</button>
                  </div>
                  <div className="Formulaire__buttons-container_forum">
                    <button onClick={sendEmail} type="button">Envoyer</button>
                  </div>
                </div>
              </form>
              </Fade>
          </div>
        </div>
        <Fade right duration={3000}>
        <div className="cadre">
            <div className="Information__cadre">
              <h2 className="Information__cadre_titre">Nos informations</h2>
              <p className="Information__cadre_Information">
                <span>contact@dmnova.com</span>
                <span>+216 55 544 822</span>
                <span>Pépinière Soft-tech du technopôle de Sousse, NOVATION CITY</span>
              </p>
            </div>
        </div>
        </Fade>
      </div>
    </div>
  );
}

export default Formulaire;

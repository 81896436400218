import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Service from './components/pages/Services/Services';
import Contact from './components/pages/Contact/Contact';
import Apropos from './components/pages/Apropos/Apropos';
import Rejoigner from './components/pages/Rejoigner/Rejoigner';
import Client_Douté from './components/pages/Client_Douté/Client_Douté';

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/services" element={<Service />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/Apropos" element={<Apropos />} />
        <Route path="/Rejoignez-nous" element={<Rejoigner />} />
        <Route path="/Client_Douté" element={<Client_Douté />} />
      </Routes>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();

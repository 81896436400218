import React from 'react'
import Navbar from '../../layout/Navbar/Navbarr'; 
import Formulaire from '../../common/Formulaire/Formulaire'; 

function Client_Douté() {
  return (
    <div>
      <div className="navbar_background">
      <Navbar/>
      </div>
      <Formulaire/>
    </div>
  )
}

export default Client_Douté;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Group from '../../../assets/images/Group.png';
import Group2 from '../../../assets/images/Group 171.png';
import Group3 from '../../../assets/images/Group 172.png';
import Group4 from '../../../assets/images/Group 173.png';
import './Accueil_Service.css';
import '../../../assets/styles/styles.css';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";


function Accueil_Service() {
  const navigate = useNavigate();
  const [theme, setTheme] = useState(localStorage.getItem('theme'));

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);



  return (
    <div className="Service">
      <Helmet>
     
   
    <title>DM Nova - Solutions en AR, VR, Jeux 2D/3D et IA au Service du Marketing</title>
    
  
    <meta name="description" content="Découvrez DM Nova, votre partenaire pour des solutions innovantes en réalité augmentée (AR), réalité virtuelle (VR) et jeux 2D/3D. Transformez votre marketing avec nos services d'intelligence artificielle et de gamification."/>


      </Helmet>
      <h2 className="Service_Accueil_titre">Nos services</h2>
      <div className="Service__container">
        <div className="Service__cadre">
          <img src={Group} alt="Image" className="Service__cadre_image" />
          <h2 className="Service__cadre_titre">Développement de solutions de gamification marketing</h2>
          <p className="p">Nous croyons que la gamification est un outil puissant pour engager votre public et créer des expériences mémorables.</p>
          <div className="readMore">
            <Link to={{ pathname: '../Services', hash: '#gamification-marketing' }} >Read More</Link>
          </div>
        </div>

        <div className="Service__cadre">
          <img src={Group2} alt="Image" className="Service__cadre_image2" />
          <h2 className="Service__cadre_titre2">Développement AR/VR</h2>
          <p className="p2">Découvrez nos services AR/VR de pointe ! Nos expériences immersives sont mémorables.</p>
          <div className="readMore">
            <Link to={{ pathname: '../Services', hash: '#developpement-ar-vr' }} >Read More</Link>
          </div>
        </div>

        <div className="Service__cadre">
          <img src={Group3} alt="Image" className="Service__cadre_image3" />
          <h2 className="Service__cadre_titre3">Développement de solutions d'IA</h2>
          <p className="p3">Nous intégrons des fonctions d'intelligence artificielle dans nos solutions pour créer des expériences utilisateur plus immersives.</p>
          <div className="readMore">
            <Link to={{ pathname: '../Services', hash: '#solutions-IA' }} >Read More</Link>
          </div>
        </div>

        <div className="Service__cadre">
          <img src={Group4} alt="Image" className="Service__cadre_image4" />
          <h2 className="Service__cadre_titre4">Développement de jeux 2D et 3D</h2>
          <p className="p4">Nous avons une équipe de développeurs de jeux hautement qualifiés qui peuvent créer des jeux immersifs et interactifs en 2D et 3D sur les plateformes Unity et Unreal.</p>
          <div className="readMore">
            <Link to={{ pathname: '../Services', hash: '#jeux-2D-et-3D' }} >Read More</Link>
          </div>
        </div>
      </div>
      <button className="btn_projet" onClick={() => navigate('../Client_Douté')}>Commencez un projet</button>
    </div>
  );
}

export default Accueil_Service;

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import logoDark from '../../../assets/images/Mask group.png';
import logoLight from '../../../assets/images/Mask group2.png';
import imageDark from '../../../assets/images/LIGHT switch.png';
import imageLight from '../../../assets/images/LIGHT switch2.png';
import '../../../assets/styles/styles.css';
import './Navbar.css';

function Navbarr({ transparentBackground }) {

  const [theme, setTheme] = useState(localStorage.getItem('theme'));
  const [isMobile, setIsMobile] = useState(false);
  const [activeLink, setActiveLink] = useState('');
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  const location = useLocation();
  const [showMobileMenuLinks, setShowMobileMenuLinks] = useState(false);
  const switchTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
  };

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  const handleClick = (link) => {
    setIsMobile(false);
    setActiveLink('');
    document.querySelector('body').classList.add('scroll-to-top');
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      document.querySelector('body').classList.remove('scroll-to-top');
    }, 100);
  };

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMobileMenuLinkClick = () => {
    setIsMobile(false);
    setShowMobileMenuLinks(false);
  };

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const handleDropdownEnter = () => {
    setIsDropdownVisible(true);
  };

  const toggleDropdownMenu = () => {
    setShowMobileMenuLinks(!showMobileMenuLinks);
  };

  return (
    <>
      <nav className={`navbar ${transparentBackground ? 'transparent' : ''}`} data-theme={theme}>
        <Link to="/">
          <div className={`navbar__logo ${transparentBackground ? 'dark' : ''}`}>
            <img
              src={
               
                   theme === 'dark'
                    ? logoDark
                    : logoLight
                
              }
              width={"100%"}
              alt="Logo"
           
            />
          </div>
        </Link>

        <ul className={`navbar__links ${transparentBackground ? 'white' : ''} ${isMobile ? 'mobile-open' : ''}`}>
          <li>
            <Link to="/" className={activeLink === '/' ? 'active' : ''} onClick={() => handleClick('/')}>
              Accueil
            </Link>
          </li>
          <li>
            <Link to="/services" className={activeLink === '/services' ? 'active' : ''} onClick={() => handleClick('/services')}>
              Services
            </Link>
          </li>
          <li>
            <Link to="/contact" className={activeLink === '/contact' ? 'active' : ''} onClick={() => handleClick('/contact')}>
              Contact
            </Link>
          </li>
          <li>
            <Link to="/apropos" className={activeLink === '/apropos' ? 'active' : ''} onClick={() => handleClick('/apropos')}>
              A propos
            </Link>
          </li>
        </ul>

        <div className='bloc'>
          <button className="navbar__button" onClick={switchTheme}>
            <img
              src={theme === 'dark' ? imageDark : imageLight}
              alt="Image"
              className="navbar__button-image_mode"
            />
          </button>

          {isSmallScreen && (
            <div className={`mobile-menu-icon ${isMobile ? 'active' : ''}`} onClick={toggleDropdownMenu}>
              {showMobileMenuLinks ? (
                <FaTimes className="white-icon" />
              ) : (
                <FaBars className="white-icon" />
              )}
              {showMobileMenuLinks && (
                <ul className="dropdown-menu">
                  <li>
                    <Link to="/" className={activeLink === '/' ? 'active' : ''} onClick={handleMobileMenuLinkClick}>
                      Accueil
                    </Link>
                  </li>
                  <li>
                    <Link to="/services" className={activeLink === '/services' ? 'active' : ''} onClick={handleMobileMenuLinkClick}>
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" className={activeLink === '/contact' ? 'active' : ''} onClick={handleMobileMenuLinkClick}>
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link to="/apropos" className={activeLink === '/apropos' ? 'active' : ''} onClick={handleMobileMenuLinkClick}>
                      A propos
                    </Link>
                  </li>
                  <li>
                    <Link to="/Rejoignez-nous" className={activeLink === '/Rejoignez-nous' ? 'active' : ''} onClick={handleMobileMenuLinkClick}>
                      Rejoignez-nous
                    </Link>
                  </li>
                </ul>

              )}
            </div>

          )}
        </div>
      </nav>
    </>

  );
}

export default Navbarr;

import React, { useState } from 'react';
import { useEffect } from 'react';
import useLocalStorage from 'use-local-storage';
import Frame2 from '../../../assets/images/Frame2.png';
import Frame from '../../../assets/images/Frame.png';
import './Accueil_About.css';
import "../../../assets/styles/styles.css";

function Accueil_About() {
  const [theme, setTheme] = useState(localStorage.getItem('theme'));

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <div className="Accueil_About">

      <div className="Accueil_About__container">
        <div className="Accueil_About__block">
          <h1 className="Accueil_About_titre">Qui somme-nous</h1>
          <p className="Accueil_About_paragraphe">
            DM Nova, une startup passionnée par l'innovation et la technologie.
            Nous sommes spécialisés dans la création de solutions innovantes pour
            aider les entreprises à atteindre leurs objectifs.
          </p>
        </div>
        <div className='contour'>
          <img src={Frame2} alt="Ma image" className="Frame2" />
        </div>

      </div>

      <div className="Accueil_About__container2">
        <div className='bloc_contour'>
          <div className='contour2'>
            <img src={Frame} alt="Ma image" className="Frame" />
          </div>
        </div>
        <div className="Accueil_About__block2">
          <h1 className="Accueil_About_titre2">
            <span className="white-text">Nous faisons</span>
            <span className="purple-text">vivre les choses</span>
          </h1>
          <p className="Accueil_About_paragraphe2">
            Plongez dans un monde virtuel captivant avec nos applications AR,
            explorez des univers de jeux fascinants et engageants, et transformez
            votre stratégie marketing avec des campagnes interactives et ludiques.
            Notre expertise en IA vous permet de bénéficier des fonctionnalités avancées
            telles que la reconnaissance vocale, la synthèse vocale et les systèmes de recommandation.
          </p>
        </div>
      </div>
    </div>

  );
}

export default Accueil_About;

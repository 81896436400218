import React from 'react';
import Navbar from '../Navbar/Navbarr';
import Accueil_Introduction from '../../common/Accueil_Introduction/Accueil_Introduction';
import '../Header/header.css';

function Header() {
  return (
    <div className='header_container'> 
      <Navbar transparentBackground={true} /> 
      <Accueil_Introduction/>
    </div>
  );
}

export default Header;

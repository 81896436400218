import React, { useState } from 'react';
import {useEffect } from 'react';
import useLocalStorage from 'use-local-storage';
import Contact from '../../../assets/images/DM Nova web site (7).gif';
import scrollDark from '../../../assets/images/Scroll down.png';
import scrollImageLight from '../../../assets/images/Group 181.png';
import Fade from 'react-reveal/Fade';
import "../../../assets/styles/styles.css";
import './Rejoigner_Introduction.css';

function Rejoigner_Introduction() {


    const handleScroll = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    });
  };

  const [theme, setTheme] =useState(localStorage.getItem('theme'));

  useEffect(() => {
      document.documentElement.setAttribute('data-theme', theme);
      localStorage.setItem('theme', theme);
    }, [theme]);


  return (
    <div  >
        <div className="Rejoigner__container" >
          <div className="Rejoigner__block">
          <Fade top duration={3000}>
            <h1 className="Rejoigner_titre">
            Nous sommes impatients de recevoir votre candidature !
            </h1>
           
            <p className="Rejoigner_paragraphe">
            Rejoignez DM Nova dès aujourd'hui pour créer des solutions novatrices qui valorisent 
            la diversité, l'innovation et l'esprit d'équipe.
            </p>
            </Fade>
          </div>
        </div>

        <div className='scroll__container'>
          <div className="scroll__button">
            <button className= "scroll__button" onClick={handleScroll}>
              <img
                src={theme === 'dark' ? scrollDark : scrollImageLight}
                alt="btn img"
                className="scroll__button-image"
              />
            </button>
          </div>
        </div>
      </div>
  );
}

export default Rejoigner_Introduction;

import React, { useEffect, useState } from 'react';
import './App.css';
import Accueil from './components/pages/Accueil/Accueil';

function App() {
  const [theme, setTheme] =useState(localStorage.getItem('theme'));

  useEffect(() => {
    
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);
  useEffect(()=>{
    if(!theme)
    setTheme('dark')
  },[])
  return (
    <>
      <Accueil />
    </>
  );
}

export default App;

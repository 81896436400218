import React from "react";
import aproposImage from "../../../assets/images/apropos.png";
import "./Apropos_Definition_SmallScreen.css";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";
function SmallScreen() {
  return (
    <div className="Apropos_SmallScreen">
      <Helmet>
        <title>
          À Propos de DM Nova - Expertise en AR, Jeux 2D/3D, Gamification et IA
        </title>

        <meta
          name="description"
          content="Découvrez DM Nova, une entreprise de technologies innovantes spécialisée en réalité augmentée (AR), jeux immersifs 2D/3D, gamification marketing et solutions d'intelligence artificielle (IA)."
        />
      </Helmet>
      <div className="content">
        <Fade left duration={3000}>
          <img className="left-side" src={aproposImage} alt="Any Image" />
        </Fade>
        <Fade right duration={3000}>
          <p className="p_SmallScreen">
            Notre entreprise a été fondée avec une vision claire : créer des
            solutions technologiques uniques pour aider les entreprises à
            atteindre leurs objectifs
            <span className="custom-span">
              commerciaux. Nous sommes passionnés par l'innovation et nous nous
              engageons à fournir à nos clients des solutions personnalisées
              pour répondre à leurs besoins uniques.
            </span>
            <span className="custom-span">
              Chez DM Nova, nous croyons que la technologie est un outil
              puissant pour changer le monde, et nous sommes fiers d'être à la
              pointe de l'innovation. Nous sommes engagés à aider nos clients à
              réussir en offrant des solutions de pointe qui ont un impact réel
              sur leurs entreprises.
            </span>
            <span className="custom-span">
              Nous sommes heureux de travailler avec des clients de différents
              secteurs, notamment des agences de communication et des
              entreprises B2B. Si vous êtes intéressés à travailler avec nous,
              n'hésitez pas à nous contacter.
            </span>
          </p>
        </Fade>
      </div>
    </div>
  );
}

export default SmallScreen;

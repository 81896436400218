import React from 'react';
import {useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import AR from '../../../assets/images/AR.png';
import ARlight from '../../../assets/images/img2.png';
import IA from '../../../assets/images/img1.png';
import marketing from '../../../assets/images/img3.png';
import jeux from '../../../assets/images/img4.png';
import Fade from 'react-reveal/Fade';
import './About.css';
import "../../../assets/styles/styles.css";
import { useLocation } from 'react-router-dom';


function About() {
  const [hovered, setHovered] = useState(false);

  const navigate = useNavigate();

  const [theme, setTheme] =useState(localStorage.getItem('theme'));

useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div className="About_service">
      <Fade left duration={3000}>
      <div className="About__container" >
        <div className="About__block">
          <img src={theme === 'dark' ? AR : ARlight } alt="Ma image" className="image_AR" />
        </div>
        <div className="About__block_info" id="developpement-ar-vr">
       
          <h1 className="titre">Développement AR/VR</h1>
          <p className="paragraphe">
          Découvrez nos services AR/VR innovants ! Nous concevons des solutions en 
          réalité augmentée sur mesure, incluant les projections 3D, les rull-up 3D, 
          les packagings AR, les publicités AR et les essais virtuels. Plongez vos utilisateurs 
          dans des mondes virtuels captivants grâce à notre expertise en Réalité Virtuelle. 
          Des simulations réalistes aux jeux VR stimulants, nos expériences immersives sont inoubliables.
          </p>
          <div>
          <button
          className="btn" onClick={() => navigate('../Client_Douté')}>Commencez un projet</button>
          </div>
        </div>
      </div>
      </Fade>
      <Fade right duration={3000}>
      <div className="About__container2">
        <div className="About__block2" id="jeux-2D-et-3D">
          <h1 className="titre2">Développement de jeux  2D et 3D</h1>
          <p className="paragraphe2">
            Nous avons une équipe de développeurs de jeux hautement qualifiés qui peuvent créer des jeux
            immersifs et interactifs en 2D et 3D sur les plateformes Unity et Unreal. Nous travaillons en
            étroite collaboration avec nos clients pour créer des jeux personnalisés qui correspondent à
            leurs marques et qui répondent à leurs besoins.{" "}
          </p>
          <button className="btn2" onClick={() => navigate('../Client_Douté')}>Commencez un projet</button>
        </div>
        <div className="About__block2">
          <img src={jeux} alt="Ma image" className="image_AR2" />
        </div>
      </div>
      </Fade>

      <Fade left duration={3000}>
      <div className="About__containerr">
        <div className="About__blockk">
          <img src={IA} alt="Ma image" className="image_ARR" />
        </div>
        <div className="About__blockk" id="solutions-IA">
          <h1 className="titree">Développement de solutions d'IA</h1>
          <p className="paragraphee">
            Nous intégrons des fonctions d'intelligence artificielle dans nos solutions pour
            créer des expériences utilisateur plus immersives. Nos solutions d'IA incluent la reconnaissance vocale,
            la synthèse vocale et les systèmes de recommandation.
            Nous travaillons avec vous pour créer des solutions sur mesure qui répondent à vos besoins spécifiques.
          </p>
          <button className="btnn" onClick={() => navigate('../Client_Douté')}>Commencez un projet</button>
           
        </div>
      </div>
      </Fade>

      <Fade right duration={3000}>
      <div className="Service_About__container3">
        <div className="Service_About__block3" id="gamification-marketing" >
          <h1 className="Service_About_titre3">Développement de solutions de gamifaction marketing</h1>
          <p className="Service_About_paragraphe3">
             Nous croyons que la gamification est un outil puissant pour engager 
             votre public et créer des expériences mémorables. Nous pouvons vous aider 
             à créer des campagnes de marketing interactif en utilisant des solutions 
             de gamification pour susciter l'intérêt de votre public et renforcer l'image de votre marque.
          </p>
          <button className="btn4" onClick={() => navigate('../Client_Douté')}>Commencez un projet</button>
        </div>
        <div className="Service_About__block3">
          <img src={marketing} alt="Ma image" className="image_AR3" />
        </div>
      </div>
      </Fade>
    </div>
  );
}

export default About;

import React, { useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import '../../../assets/styles/styles.css';
import './Rejoigner_formulaire.css';

function Rejoigner_formulaire() {
  const [rotateBackground, setRotateBackground] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem('theme'));
  const [selectedFile, setSelectedFile] = useState(null); //pour assurer de joindre un fichier

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  //pour assurer de joindre un fichier
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file.name);
    } else {
      setSelectedFile(null);
    }
  };

  return (
   /*  <div className="Rejoigner_Formulaire">
      <div className="Rejoigner_Formulaire__container">
        <div className="Rejoigner_form">
          <div className="Rejoigner_Formulaire__block">
            <Fade left duration={3000}>
              <form>
                <div className="Rejoigner_Formulaire__inputs-container">
                  <input type="text" name="nom" placeholder="Nom" required />
                  <input type="text" name="Prenom" placeholder="Prénom" required />
                </div>
                <div className="Rejoigner_Formulaire__inputs-container">
                  <input type="tel" name="telephone" placeholder="Numéro de téléphone" required />
                  <input type="email" name="email" placeholder="E-mail" required />
                </div>
                <div className="subject">
                  <div className="Rejoigner_Formulaire__inputs-container">
                    <select name="sujet" required>
                      <option value="" disabled selected hidden>Sujet</option>
                      <option value="sujet1">Candidature spontanée</option>
                      <option value="sujet2">Offre d'emploi</option>
                      <option value="sujet3">Demande de stage</option>
                    </select>
                  </div>
                </div>

                <div className="Rejoigner_Formulaire__inputs-container">
                  <input
                    type="file"
                    name="joindre un fichier"
                    accept=".pdf"
                    required
                    onChange={handleFileChange}
                  />
                </div>

                <div className="Rejoigner_Formulaire__inputs-container">
                  <textarea name="message" placeholder="Pourquoi DM nova?" required />
                </div>
                <div className="Rejoigner_Formulaire__container_btn">
                  <div>
                    <button type="reset" className="Rejoigner_Formulaire__reset-button">Réinitialiser</button>
                  </div>
                  <div className="Rejoigner_Formulaire__buttons-container">
                    <button type="submit" className="btn2">Envoyer</button>
                  </div>
                </div>
              </form>
            </Fade>
          </div>
        </div>
      </div>
    </div> */
    <></>
  );
}

export default Rejoigner_formulaire;

import React from "react";
import { useEffect } from "react";
import useLocalStorage from "use-local-storage";
import Contact from "../../../assets/images/DM Nova web site (7).gif";
import scrollDark from "../../../assets/images/Scroll down.png";
import scrollImageLight from "../../../assets/images/Group 181.png";
import "./Contact_Introduction.css";
import Fade from "react-reveal/Fade";
import "../../../assets/styles/styles.css";
import { useState } from "react";
import { Helmet } from "react-helmet";
function Contact_Introduction() {
  const handleScroll = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  const [theme, setTheme] = useState(localStorage.getItem("theme"));

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <div className="Contact">
      <Helmet>
      <title>Contactez DMNova - Solutions en AR, VR, Jeux 2D/3D et IA</title>
    

    <meta name="description" content="Besoin de solutions en réalité augmentée (AR), réalité virtuelle (VR), jeux 2D/3D, gamification marketing ou IA ? Contactez DMNova pour des réponses à vos questions."/>

      </Helmet>
      <div className="Contact__container">
        <div className="Contact__block">
          <Fade top duration={3000}>
            <h1 className="Contact_titre">
              Nous sommes ravis que vous envisagiez de travailler avec DM Nova
            </h1>
          </Fade>
          <Fade top duration={3000}>
            <p className="Contact_paragraphe_p">
              Si vous avez des questions ou si vous souhaitez obtenir des
              informations supplémentaires sur nos services, n'hésitez pas à
              nous contacter.
            </p>
          </Fade>
        </div>
        <div className="Contact__block">
          <img src={Contact} alt="image" className="Contact_img" />
        </div>
      </div>

      <div className="scroll__container">
        <div className="scroll__button">
          <button className="scroll__button" onClick={handleScroll}>
            <img
              src={theme === "dark" ? scrollDark : scrollImageLight}
              alt="btn img"
              className="scroll__button-image"
            />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Contact_Introduction;

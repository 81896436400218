import React, { useState, useEffect } from 'react';
import Accueil_About from '../../common/Accueil_About/Accueil_About'; 
import Accueil_Service from '../../common/Accueil_Service/Accueil_Service'; 
import Accueil_Service_SmallScreen from '../../common/Accueil_Service_SmallScreen/Accueil_Service_SmallScreen'; 
import Accueil_Partenaire from '../../common/Accueil_Partenaires/Accueil_Partenaire';
import Apropos_Information from '../../common/Apropos_Information/Apropos_Information';
import Apropos_Information_SmallScreen from '../../common/Apropos_Information_SmallScreen/Apropos_Information_SmallScreen'; 
import Footer from '../../layout/Footer/Footer'; 
import Header from '../../layout/Header/Header';

function Apropos() {
  const [isBigScreen, setIsBigScreen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsBigScreen(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const [isBig, setIsBig] = useState(window.innerWidth > 600);

  useEffect(() => {
    const handleResize = () => {
      setIsBig(window.innerWidth > 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
 
  return (
    <>
     <Header></Header>
      <Accueil_About/>
      {isBigScreen ? (
        <Accueil_Service/>
      ) : (
        <Accueil_Service_SmallScreen />
      )}
      <Accueil_Partenaire/>
        {isBig ? (
       <Apropos_Information/>
      ) : (
        <Apropos_Information_SmallScreen />
      )}
        <Footer/>
    </>
  );
}

export default Apropos;

import React from "react";
import { useEffect } from "react";
import useLocalStorage from "use-local-storage";
import globe from "../../../assets/images/globe2.gif";
import scrollImage from "../../../assets/images/Scroll down.png";
import scrollImageLight from "../../../assets/images/Group 181.png";
import Fade from "react-reveal/Fade";
import "./Introduction.css";
import "../../../assets/styles/styles.css";
import { useState } from "react";
import { Helmet } from "react-helmet";

function Introduction() {
  const handleScroll = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };
  const [theme, setTheme] = useState(localStorage.getItem("theme"));

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <div className="Service_S">
      <Helmet>
        <title>
          Services - Développement AR, Jeux 2D/3D, Gamification et IA | DM Nova
        </title>

        <meta
          name="description"
          content="Explorez nos services spécialisés : développement de solutions AR, jeux immersifs 2D/3D, gamification marketing et intégration d'intelligence artificielle. Contactez-nous pour concrétiser vos projets."
        />
      </Helmet>
      <div className="Service_S__container">
        <div className="Service_S__block">
          <Fade top duration={3000}>
            <h1 className="Service_S_titre">
              Notre expertise se concentre sur quatre domaines principaux
            </h1>
          </Fade>
          <Fade top duration={3000}>
            <p className="Service_S_paragraphe_p">
              Nous sommes une entreprise spécialisée dans la création des
              solutions innovantes et personnalisées pour répondre à vos besoins
              spécifiques.
            </p>
          </Fade>
        </div>
        <div className="Service_S__block">
          <img src={globe} alt="image" className="Service_S_img" />
        </div>
      </div>

      <div className="scroll__container">
        <div className="scroll__button">
          <button className="scroll__button" onClick={handleScroll}>
            <img
              src={theme === "dark" ? scrollImage : scrollImageLight}
              alt="btn img"
              className="scroll__button-image"
            />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Introduction;
